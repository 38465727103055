import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoWhite from '../skin/images/logoWhite.png';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg fixed top-0 z-50 w-screen">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <Link to="/" className="flex items-center py-4 px-2">
                <img src={logoWhite} alt="Alex Albert Logo" className="h-16 w-auto" />
              </Link>
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-1">
            <Link to="/" className="py-4 px-2 text-white font-semibold hover:text-gray-200 transition duration-300 hover:scale-110">Home</Link>
            <Link to="/book-consultation" className="py-4 px-2 text-white font-semibold hover:text-gray-200 transition duration-300 hover:scale-110">Book a Consultation</Link>
            <Link to="/workouts" className="py-4 px-2 text-white font-semibold hover:text-gray-200 transition duration-300 hover:scale-110">Workouts</Link>
            <a href="https://alexpt2.trainerize.com/app/logon.aspx" target="_blank" rel="noopener noreferrer" className="py-4 px-2 text-white font-semibold hover:text-gray-200 transition duration-300 hover:scale-110">Log in</a>
            <Link to="/contact" className="py-4 px-2 text-white font-semibold hover:text-gray-200 transition duration-300 hover:scale-110">Contact</Link>
          </div>
          <div className="md:hidden flex items-center">
            <button className="outline-none mobile-menu-button" onClick={toggleMenu}>
              <svg className="w-6 h-6 text-white hover:text-gray-200"
                x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`fixed top-0 right-0 w-64 h-full bg-blue-600 z-50 shadow-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu} className="text-white hover:text-gray-200">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center mt-24">
          <Link to="/" className="py-2 px-4 text-sm text-white font-semibold hover:text-gray-200 transition duration-300" onClick={toggleMenu}>Home</Link>
          <Link to="/book-consultation" className="py-2 px-4 text-sm text-white font-semibold hover:text-gray-200 transition duration-300" onClick={toggleMenu}>Book a Consultation</Link>
          <Link to="/workouts" className="py-2 px-4 text-sm text-white font-semibold hover:text-gray-200 transition duration-300" onClick={toggleMenu}>Workouts</Link>
          <a href="https://alexpt2.trainerize.com/app/logon.aspx" target="_blank" rel="noopener noreferrer" className="py-2 px-4 text-sm text-white font-semibold hover:text-gray-200 transition duration-300" onClick={toggleMenu}>Log in</a>
          <Link to="/contact" className="py-2 px-4 text-sm text-white font-semibold hover:text-gray-200 transition duration-300" onClick={toggleMenu}>Contact</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
