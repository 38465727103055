import React from 'react'
import logoWhite from '../skin/images/logoWhite.png'
import heroVideo from '../skin/video/heroVideo.mp4'
import ContentSection from '../components/ContentSection'
import sampleImage from '../skin/images/aboutImage.jpeg' 
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
      {/* HERO */}
      <div className="hero-container">
        <video className="hero-video" autoPlay loop muted playsInline>
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay bg-gradient-to-r from-primary/70 to-secondary/70">
          <div className="hero-content text-center">
            <div className="max-w-md text-white">
              <img src={logoWhite} alt="logo" className="w-64 mx-auto mb-6" />
              <p className="py-6 uppercase text-3xl tracking-widest">
                Personal Training
              </p>
              <Link to="/book-consultation" className="button-primary">Book a Consultation</Link>

            </div>
          </div>
        </div>
      </div>
      
      {/* CONTENT SECTION */}
      <ContentSection 
        title="ABOUT ALEX" 
        text={`Alex Albert is a dedicated and fully qualified Personal Trainer based in Chobham, renowned not only for his exceptional expertise in Strength and Conditioning but also for his distinctive, awesome hair. With a passion for helping others achieve their fitness goals, Alex works with clients from all walks of life, tailoring his approach to suit individuals of all backgrounds and skill levels.\n\nAlex's deep understanding of athletic performance stems from his impressive background as a professional football player. This experience has equipped him with firsthand knowledge of the demands of high-level sports, and he brings this insight into his training sessions. Whether you are a beginner looking to improve your overall fitness or an athlete seeking to enhance your performance, Alex's personalized training programs are designed to empower you to reach your full potential.\n\nHis holistic approach to fitness combines physical training with motivational coaching, ensuring that clients not only see results but also maintain a positive mindset throughout their fitness journey. Alex's commitment to excellence and his ability to inspire confidence in his clients make him a sought-after trainer in the Cobham area.\n\nWhen you train with Alex, you're not just getting a workout; you're embarking on a transformative journey that will enhance your strength, agility, and overall well-being. Join him, and discover what you're truly capable of achieving.`}
        image={sampleImage} 
      />
    </>
  )
}

export default Home
