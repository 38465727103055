import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import './index.css'
import './App.css'
import './tailwind.scss'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import Contact from './pages/Contact'
import Book from './pages/Book'
import ThankYou from './pages/ThankYou'

function App() {
  return (
    <>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/book-consultation" element={<Book />} />
      <Route path="/workouts" element={<div>Workouts</div>} />
      <Route path="/login" element={<div>Log in</div>} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
    <Footer />
    </>
  )
}

export default App
