import React from 'react';

function ContentSection({ title, text, image }) {
  // Function to split text into paragraphs
  const splitIntoParagraphs = (text) => {
    return text.split('\n\n').map((paragraph, index) => (
      <p key={index} className="mb-4">{paragraph}</p>
    ));
  };

  return (
    <section className="py-12 px-4 md:px-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold mb-4">{title}</h2>
            <div className="text-lg">{splitIntoParagraphs(text)}</div>
          </div>
          <div className="md:w-1/2">
            <img src={image} alt={title} className="w-full h-auto rounded-lg shadow-lg" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContentSection;
